<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="customerId != null">Edit Business Partner</b>
      <b v-else>Create Business Partner</b>
    </span>
    <div class="vx-row mb-6" v-if="customerId != null">
      <div class="vx-col w-full">
        <vs-input disabled class="w-full" label="Code" v-model="data.code" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Status</label>
        <Status :status="data.status" @data="setStatus"></Status>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Distribution Channel</label>
        <multiselect
          v-model="customerCategory.selected"
          :options="customerCategory.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerCategory')"
          >{{ errors.first("CustomerCategory") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Business Partner Group</label>
        <multiselect
          v-model="customerGroup.selected"
          :options="customerGroup.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerGroup')"
          >{{ errors.first("CustomerGroup") }}</span
        >
      </div>
    </div>
    <!-- <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Method</label>
        <payment-method
          :id="data.paymentMethodId"
          @data="setPaymentMethodId"
        ></payment-method>
        <span
          class="text-danger text-sm"
          v-show="errors.has('PaymentMethod')"
          >{{ errors.first("PaymentMethod") }}</span
        >
      </div>
    </div> -->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Term</label>
        <multiselect
          v-model="paymentTerm.selected"
          :options="paymentTerm.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('PaymentTerm')">{{
          errors.first("PaymentTerm")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Pricing Group</label>
        <multiselect
          v-model="pricingGroup.selected"
          :options="pricingGroup.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.name }} ({{ props.option.code }})</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.name }} ({{ props.option.code }})</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          v-model="territory.selected"
          :options="territory.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :multiple="true"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <address-component
      :data="data.address"
      @data="setDataAddress"
    ></address-component>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Contact Name"
          v-model="data.contactName"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Phone"
          v-model="data.phone"
          v-validate="'required|numeric'"
          name="Phone"
        />
        <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
          errors.first("Phone")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Mobile"
          v-model="data.mobile"
          v-validate="'numeric'"
          name="Mobile"
        />
        <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{
          errors.first("Mobile")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Email"
          v-model="data.email"
          v-validate="'email'"
          name="Email"
        />
        <span class="text-danger text-sm" v-show="errors.has('Email')">{{
          errors.first("Email")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Fax"
          v-model="data.fax"
          v-validate="'numeric'"
          name="Fax"
        />
        <span class="text-danger text-sm" v-show="errors.has('Fax')">{{
          errors.first("Fax")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Credit Limit"
          v-model="data.customerLimit.amount"
          name="CreditLimit"
        />
        <span class="text-danger text-sm" v-show="errors.has('CreditLimit')">{{
          errors.first("CreditLimit")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Invoice Model</label>
        <br />
        <ul class="centerx">
          <li>
            <vs-radio v-model="data.invoiceModel" vs-value="When Shipping"
              >When Shipping</vs-radio
            >&nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <vs-radio v-model="data.invoiceModel" vs-value="Separated"
              >Separated</vs-radio
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Priority</label>
        <priority-type
          :value="data.priorityType"
          @data="setPriorityType"
        ></priority-type>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Trader</label>
        <TraderType :value="data.traderType" @data="setTraderType"></TraderType>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">PKP</label>
        <vs-switch
          color="dark"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.isPkp"
        >
          <span slot="on">YES</span>
          <span slot="off">NO</span>
        </vs-switch>
      </div>
    </div>
    <template>
      <div v-if="data.isPkp == true">
        <div class="vx-row mb-3">
          <div class="vx-col w-full ml-6">
            <vs-input
              class="w-full"
              label="NPWP"
              v-model="data.npwp"
              name="NPWP"
            />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full ml-6">
            <vs-input
              class="w-full"
              label="TDP"
              v-model="data.tdp"
              name="TDP"
            />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full ml-6">
            <vs-input
              class="w-full"
              label="SIUP"
              v-model="data.siup"
              name="SIUP"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full ml-6">
            <vs-input
              class="w-full"
              label="Domisili"
              v-model="data.domisili"
              name="domisili"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
//import PaymentMethod from "./form-component/PaymentMethod";
import Status from "./form-component/Status";
import PriorityType from "./form-component/PriorityType";
import TraderType from "./form-component/TraderType";
import AddressComponent from "@/components/master/Address";

export default {
  components: {
    //PaymentMethod,
    Status,
    PriorityType,
    AddressComponent,
    TraderType,
  },
  props: {
    customerId: {
      type: Number,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer",
        data: {
          customerGroupId: null,
          customerCategoryId: null,
          paymentTermId: null,
          //paymentMethodId: null,
          pricingGroupId: null,
          code: "",
          name: "",
          contactName: "",
          phone: "",
          mobile: "",
          email: "",
          fax: "",
          isPkp: true,
          npwp: "",
          tdp: "",
          siup: "",
          domisili: "",
          invoiceModel: "When Shipping",
          priorityType: "Standard",
          traderType: "General",
          status: "",
          customerLimit: {
            amount: 0,
          },
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            provinceName: "",
            cityName: "",
            districtName: "",
            subdistrictName: "",
          },
        },
        city: {
          id: null,
          provinceId: null,
          name: "",
        },
        customerCategory: {
          selected: null,
          options: [],
        },
        customerGroup: {
          selected: null,
          options: [],
        },
        paymentTerm: {
          selected: null,
          options: [],
        },
        pricingGroup: {
          selected: null,
          options: [],
        },
        territory: {
          selected: null,
          options: [],
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.customerCategoryId) {
          this.errors.add({
            field: "CustomerCategory",
            msg: "The Distribution Channel field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId) {
          this.errors.add({
            field: "CustomerGroup",
            msg: "The Business Partner Group field is required",
          });
          result = false;
        }
        // if (!this.data.paymentMethodId) {
        //   this.errors.add({
        //     field: "PaymentMethod",
        //     msg: "The Payment Method field is required",
        //   });
        //   result = false;
        // }
        if (!this.data.paymentTermId) {
          this.errors.add({
            field: "PaymentTerm",
            msg: "The Payment Term field is required",
          });
          result = false;
        }
        let amount = parseInt(
          this.data.customerLimit.amount.toString().replace(/\./g, "")
        );
        if (amount < 1) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is must greater than 0",
          });
          result = false;
        }

        if (result) {
          if (this.customerId) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      let is_pkp = "0";
      if (this.data.isPkp) {
        is_pkp = "1";
      } else {
        this.data.npwp = "";
        this.data.tdp = "";
        this.data.siup = "";
        this.data.domisili = "";
      }
      var territoryIds = [];
      if (Array.isArray(this.territory.selected)) {
        this.territory.selected.forEach(function (item) {
          territoryIds.push(item.id);
        }, territoryIds);
      }

      return {
        customer_group_id: this.data.customerGroupId,
        payment_term_id: this.data.paymentTermId,
        //payment_method_id: this.data.paymentMethodId,
        pricing_group_id: this.data.pricingGroupId,
        customer_category_id: this.data.customerCategoryId,
        territory_ids: territoryIds,
        code: this.data.code,
        name: this.data.name,
        // address: this.data.address,
        // city: this.data.city,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        contact_name: this.data.contactName,
        mobile: this.data.mobile,
        phone: this.data.phone,
        email: this.data.email,
        fax: this.data.fax,
        is_pkp: is_pkp,
        npwp: this.data.npwp,
        tdp: this.data.tdp,
        siup: this.data.siup,
        domisili: this.data.domisili,
        type: "Business Partner",
        invoice_model: this.data.invoiceModel,
        priority_type: this.data.priorityType,
        customer_type: this.data.traderType,
        status: this.data.status,
        amount: parseInt(
          this.data.customerLimit.amount.toString().replace(/\./g, "")
        ),
      };
    },
    postData() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Business Partner Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$vs.loading();
      this.$http
        .put(this.baseUrl + "/" + this.customerId, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Business Partner Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerId) {
        this.$http
          .get(this.baseUrl + "/" + this.customerId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.code = resp.data.code;
              this.data.name = resp.data.name;
              // this.data.address = resp.data.address;
              this.data.contactName = resp.data.contact_name;
              this.data.phone = resp.data.phone;
              this.data.mobile = resp.data.mobile;
              this.data.email = resp.data.email;
              this.data.fax = resp.data.fax;
              //this.data.paymentMethodId = resp.data.payment_method_id;
              if (resp.data.invoice_model) {
                this.data.invoiceModel = resp.data.invoice_model;
              }
              if (resp.data.priority_type) {
                this.data.priorityType = resp.data.priority_type;
              }
              this.data.traderType = resp.data.customer_type;
              if (resp.data.status) {
                this.data.status = resp.data.status;
              }
              // this.city.name = resp.data.city;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;
              if (resp.data.is_pkp == "1") {
                this.data.ispkp = true;
              } else {
                this.data.ispkp = false;
              }
              this.data.npwp = resp.data.npwp;
              this.data.tdp = resp.data.tdp;
              this.data.siup = resp.data.siup;
              this.data.domisili = resp.data.domisili;
              this.data.customerLimit.amount = resp.data.customer_limit.amount;
              if (resp.data.customer_category_id) {
                this.setCustomerCategorySelected(
                  resp.data.customer_category_id
                );
              }

              if (resp.data.customer_group_id) {
                this.setCustomerGroupSelected(resp.data.customer_group_id);
              }

              if (resp.data.payment_term_id) {
                this.setPaymentTermSelected(resp.data.payment_term_id);
              }

              if (resp.data.pricing_group_id) {
                this.setPricingGroupSelected(
                  resp.data.pricing_group_id
                );
              }

              this.territory.selected = [];
              var getDataTerritory = this.getDataTerritory;
              resp.data.customer_territory.forEach(function (item) {
                getDataTerritory(item.territory_id);
              }, getDataTerritory);
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    getAllDataCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-category", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-group", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
            //payment_method_id: this.data.paymentMethodId,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.paymentTerm.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPricingGroup() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/pricing-group", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.pricingGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTerritory(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.territory.selected.push(resp.data);
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerCategorySelected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-category/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerCategory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroupSelected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPaymentTermSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/payment-term/" + id).then((resp) => {
        if (resp.status == "success") {
          this.paymentTerm.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPricingGroupSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/pricing-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.pricingGroup.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    // setPaymentMethodId(val) {
    //   console.log(val)
    //   this.data.paymentMethodId = val;
    // },
    setStatus(val) {
      this.data.status = val;
    },
    setPriorityType(val) {
      this.data.priorityType = val;
    },
    setTraderType(val) {
      this.data.traderType = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
  },
  mounted() {
    this.getAllDataCustomerCategory();
    this.getAllDataCustomerGroup();
    this.getAllDataPaymentTerm();
    this.getAllDataPricingGroup();
    this.getAllDataTerritory();
    if (this.customerId) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    customerId(v) {
      this.getAllDataCustomerCategory();
      this.getAllDataCustomerGroup();
      this.getAllDataPaymentTerm();
      this.getAllDataPricingGroup();
      this.getAllDataTerritory();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "customerCategory.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.customerCategoryId = val.id;
      } else {
        this.data.customerCategoryId = null;
      }
    },
    "customerGroup.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.customerGroupId = val.id ? val.id : val.ID;
      } else {
        this.data.customerGroupId = null;
      }
    },
    "paymentTerm.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.paymentTermId = val.id ? val.id : val.ID;
      } else {
        this.data.paymentTermId = null;
      }
    },
    "pricingGroup.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.pricingGroupId = val.id ? val.id : val.ID;
      } else {
        this.data.pricingGroupId = null;
      }
    },
    "data.customerLimit.amount": function (val) {
      if (val != null) {
        val = val.toString().replace(/\./g, "");
        if (isNaN(parseInt(val))) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is number required",
          });
        } else {
          this.errors.clear();
        }
        this.data.customerLimit.amount = val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        this.data.customerLimit.amount = 0;
      }
    },
    // "data.paymentMethodId": function (val) {
    //   if (this.paymentTerm.selected) {
    //     if (this.paymentTerm.selected.payment_method_id != val) {
    //       this.paymentTerm.selected = null;
    //     }
    //   }
    //   this.getAllDataPaymentTerm();
    // },
  },
};
</script>
<style >
</style>
