<template>
  <div class="vx-row mb-6" style="width:70%">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Territory</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        track-by="name"
        label="name"
        :max-height="125"
        :allow-empty="false"
      >
        <span slot="noResult">Oops! No data found</span>
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
          </span>
        </template>

        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baseUrl: "/api/v1/master/territory",
      options: [],
      selected: null
    };
  },
  methods: {
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            var options = [];
            options.push({
              id: 0,
              name: "All"
            });
            this.selected = options[0];
            resp.data.records.forEach(function(item) {
              options.push(item);
            }, options);
            this.options = options;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    }
  },
  watch: {
    selected(v) {
      this.selected = v;
      if (v) {
        this.$emit("data", v.id);
      } else {
        this.$emit("data", v.id);
      }
    }
  },
  mounted() {
    this.getData();
  }
};
</script>