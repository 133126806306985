<template>
  <div>
    <ul class="centerx">
      <li v-for="(item, index) in options" :key="index">
        <vs-radio v-model="selected" :vs-value="item">{{ item }}</vs-radio
        >&nbsp;&nbsp;&nbsp;
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      selected: null,
      options: ["General", "Trader"],
      search: "",
    };
  },
  methods: {},
  watch: {
    selected(v) {
      if (v) {
        this.$emit("data", v);
      } else {
        this.$emit("data", null);
      }
    },
    value(v) {
      this.selected = v;
    },
  },
  mounted() {
    this.selected = this.value;
  },
};
</script>