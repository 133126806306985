<template>
  <multiselect
    v-model="selected"
    :options="options"
    :searchable="false"
    :showNoOptions="false"
    :max-height="125"
    :allow-empty="false"
  ></multiselect>
</template>
<script>
export default {
  props: {
    status: {
      type: String
    }
  },
  data() {
    return {
      selected: null,
      options: ["Active", "Inactive", "On Hold"],
      search: ""
    };
  },
  methods: {},
  watch: {
    selected(v) {
      if (v) {
        this.$emit("data", v);
      } else {
        this.$emit("data", null);
      }
    },
    status(v) {
      this.selected = v;
    }
  },
  mounted() {
    this.selected = this.status;
  }
};
</script>